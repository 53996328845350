import image from '~/assets/images/approach/approach-info-round.svg?file'
import * as containerStylesSpriteIcons from '~/styles/modules/SpriteIcons.module.scss'
import { cx } from '~/utils'

import * as containerStyles from './InfoRoundBlock.module.scss'

const ApproachInfoRoundBlock = () => (
  <div className="container container-md">
    <div className="row">
      <div
        className={cx(
          containerStyles.approachInfoRoundBlock__between,
          'col-12 col-xl-3 col-md-6',
        )}
      >
        <div
          className={cx(
            containerStyles.approachInfoRoundBlock__card,
            containerStyles.approachInfoRoundBlock__contentOne,
          )}
        >
          <div
            className={cx(
              containerStyles.approachInfoRoundBlock__icon,
              containerStylesSpriteIcons.spriteIconsWrapper,
              containerStylesSpriteIcons.spriteIconsWrapper__sprite,
              containerStylesSpriteIcons.spriteIconsWrapper__spriteIcon_76,
            )}
          />
          <div className={containerStyles.approachInfoRoundBlock__title}>
            Acceptance tests
          </div>
          <p className={containerStyles.approachInfoRoundBlock__description}>
            It’s an essential practice serving to achieve app compliance with
            business requirements.
          </p>
        </div>

        <div
          className={cx(
            containerStyles.approachInfoRoundBlock__card,
            containerStyles.approachInfoRoundBlock__contentTwo,
          )}
        >
          <div
            className={cx(
              containerStyles.approachInfoRoundBlock__icon,
              containerStylesSpriteIcons.spriteIconsWrapper,
              containerStylesSpriteIcons.spriteIconsWrapper__sprite,
              containerStylesSpriteIcons.spriteIconsWrapper__spriteIcon_70,
            )}
          />
          <div className={containerStyles.approachInfoRoundBlock__title}>
            Following coding standards
          </div>
          <p className={containerStyles.approachInfoRoundBlock__description}>
            Developers follow consistency by using standardized styles and
            formats for code writing.
          </p>
        </div>

        <div
          className={cx(
            containerStyles.approachInfoRoundBlock__card,
            containerStyles.approachInfoRoundBlock__contentThree,
          )}
        >
          <div
            className={cx(
              containerStyles.approachInfoRoundBlock__icon,
              containerStylesSpriteIcons.spriteIconsWrapper,
              containerStylesSpriteIcons.spriteIconsWrapper__sprite,
              containerStylesSpriteIcons.spriteIconsWrapper__spriteIcon_78,
            )}
          />
          <div className={containerStyles.approachInfoRoundBlock__title}>
            Refactoring
          </div>
          <p className={containerStyles.approachInfoRoundBlock__description}>
            We regularly review our project’s codebases and refactor them to
            avoid any technical debt.
          </p>
        </div>
      </div>

      <div className="col-6 d-none d-xl-block">
        <img
          src={image}
          loading="lazy"
          width="560"
          height="465"
          alt="Software development best practices | Codica"
          title="Software development best practices"
          className={containerStyles.approachInfoRoundBlock__imageDesktop}
        />
      </div>

      <div
        className={cx(
          containerStyles.approachInfoRoundBlock__between,
          'col-12 col-xl-3 col-md-6',
        )}
      >
        <div
          className={cx(
            containerStyles.approachInfoRoundBlock__card,
            containerStyles.approachInfoRoundBlock__contentFour,
          )}
        >
          <div
            className={cx(
              containerStyles.approachInfoRoundBlock__icon,
              containerStylesSpriteIcons.spriteIconsWrapper,
              containerStylesSpriteIcons.spriteIconsWrapper__sprite,
              containerStylesSpriteIcons.spriteIconsWrapper__spriteIcon_77,
            )}
          />
          <div className={containerStyles.approachInfoRoundBlock__title}>
            Pair programming
          </div>
          <p className={containerStyles.approachInfoRoundBlock__description}>
            This method helps us a lot in delivering the right architectural
            solutions and gaining quality code.
          </p>
        </div>

        <div
          className={cx(
            containerStyles.approachInfoRoundBlock__card,
            containerStyles.approachInfoRoundBlock__contentFive,
          )}
        >
          <div
            className={cx(
              containerStyles.approachInfoRoundBlock__icon,
              containerStylesSpriteIcons.spriteIconsWrapper,
              containerStylesSpriteIcons.spriteIconsWrapper__sprite,
              containerStylesSpriteIcons.spriteIconsWrapper__spriteIcon_78,
            )}
          />
          <div className={containerStyles.approachInfoRoundBlock__title}>
            Code reviews
          </div>
          <p className={containerStyles.approachInfoRoundBlock__description}>
            Constant code quality reviews guarantee stable, fast-acting, and
            high-quality solutions.
          </p>
        </div>

        <div
          className={cx(
            containerStyles.approachInfoRoundBlock__card,
            containerStyles.approachInfoRoundBlock__contentSix,
          )}
        >
          <div
            className={cx(
              containerStyles.approachInfoRoundBlock__icon,
              containerStylesSpriteIcons.spriteIconsWrapper,
              containerStylesSpriteIcons.spriteIconsWrapper__sprite,
              containerStylesSpriteIcons.spriteIconsWrapper__spriteIcon_68,
            )}
          />
          <div className={containerStyles.approachInfoRoundBlock__title}>
            Test-Driven Development
          </div>
          <p className={containerStyles.approachInfoRoundBlock__description}>
            Tests before implementation enhance coding, testing, and product
            design.
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default ApproachInfoRoundBlock
