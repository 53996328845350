import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

import subTractBottomImage from '~/assets/images/approach/subtract-approach.svg?file'
import SubtractBlock from '~/components/shared/SubtractBlock'
import { Item } from '~/types/data-array'
import { PhotosQuery } from '~/types/graphql'
import { fileToImageLikeData } from '~/utils'

import * as containerStyles from './PrimeSectionApproach.module.scss'

interface PrimeSectionApproachProps {
  title: string
  description: Item[]
}

const PrimeSectionApproach = ({
  title,
  description,
}: PrimeSectionApproachProps) => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      primImage: file(
        relativePath: { eq: "approach/approach-prime-section.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  const primImage = getImage(fileToImageLikeData(query.primImage))

  return (
    <section className={containerStyles.primeSectionApproach}>
      <div className="container container-md">
        <div className="row">
          <div className="col-12">
            <h1 className={containerStyles.primeSectionApproach__title}>
              {title}
            </h1>
            {description.map(({ item }) => (
              <div
                key={String(item)}
                className={containerStyles.primeSectionApproach__description}
              >
                {item}
              </div>
            ))}
            <div className={containerStyles.primeSectionApproach__image}>
              <GatsbyImage
                image={primImage as IGatsbyImageData}
                alt={`${title} | Codica`}
                title={title}
                loading="eager"
              />
            </div>
          </div>
        </div>
      </div>
      <SubtractBlock isCustomImg image={subTractBottomImage} loading="eager" />
    </section>
  )
}

export default PrimeSectionApproach
