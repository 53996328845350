import { StaticImage } from 'gatsby-plugin-image'

import { CommonImageProps } from '~/types/common-props'
import { TeamProjectSuccessItem } from '~/views/Approach/components/TeamProjectSuccess/TeamProjectSuccess.view'

const designer1 = '../../assets/images/avatars/Inessa.png'
const designer2 = '../../assets/images/avatars/Designer-Anastasia.png'
const designer3 = '../../assets/images/avatars/Designer-Anna.png'
const devops1 = '../../assets/images/avatars/Devops-Ivan.png'
const devops2 = '../../assets/images/avatars/Devops-Illya.png'
const dev1 = '../../assets/images/avatars/FE-Dmytro.png'
const dev2 = '../../assets/images/avatars/ROR-Alisa.png'
const dev3 = '../../assets/images/avatars/FE-Artur.png'
const techLead1 = '../../assets/images/avatars/Sergey.png'
const techLead3 = '../../assets/images/avatars/FE-Eugene.png'
const techLead2 = '../../assets/images/avatars/Max.png'
const pm1 = '../../assets/images/avatars/PM-Arpi.png'
const pm2 = '../../assets/images/avatars/PM-Inna.png'
const pm3 = '../../assets/images/avatars/PM-Alexander.png'
const qa1 = '../../assets/images/avatars/QA-Oleksii.png'
const qa2 = '../../assets/images/avatars/QA-Anastasia.png'
const qa3 = '../../assets/images/avatars/QA-Alexander.png'

const imageProps: CommonImageProps = {
  width: 53,
  height: 53,
}

const TeamProjectSuccessData: TeamProjectSuccessItem[] = [
  {
    get image_1() {
      return (
        <StaticImage
          src={pm1}
          alt={`${this.title} 1 | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    get image_2() {
      return (
        <StaticImage
          src={pm2}
          alt={`${this.title} 2 | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    get image_3() {
      return (
        <StaticImage
          src={pm3}
          alt={`${this.title} 3 | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    num: '+2',
    title: 'Project managers',
    description:
      'They manage the agile web development process from the beginning to finish.',
  },
  {
    get image_1() {
      return (
        <StaticImage
          src={designer1}
          alt={`${this.title} 1 | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    get image_2() {
      return (
        <StaticImage
          src={designer2}
          alt={`${this.title} 2 | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    get image_3() {
      return (
        <StaticImage
          src={designer3}
          alt={`${this.title} 3 | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    title: 'UX/UI specialists',
    description:
      'Build the custom user interface and guarantee an enhanced user experience.',
  },
  {
    get image_1() {
      return (
        <StaticImage
          src={techLead1}
          alt={`${this.title} 1 | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    get image_2() {
      return (
        <StaticImage
          src={techLead2}
          alt={`${this.title} 2 | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    get image_3() {
      return (
        <StaticImage
          src={techLead3}
          alt={`${this.title} 3 | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    num: '+1',
    title: 'Technical leads',
    description:
      'These experts define the tech development vector and evaluate the rightness of tech solutions.',
  },
  {
    get image_1() {
      return (
        <StaticImage
          src={dev1}
          alt={`${this.title} 1 | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    get image_2() {
      return (
        <StaticImage
          src={dev2}
          alt={`${this.title} 2 | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    get image_3() {
      return (
        <StaticImage
          src={dev3}
          alt={`${this.title} 3 | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    num: '+24',
    title: 'Developers',
    description:
      'Bring ideas into a code, guided by the design and prescribed tech requirements.',
  },
  {
    get image_1() {
      return (
        <StaticImage
          src={devops1}
          alt={`${this.title} 1 | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    get image_2() {
      return (
        <StaticImage
          src={devops2}
          alt={`${this.title} 2 | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    title: 'DevOps engineers',
    description:
      'Their responsibilities are to automate tasks related to configuring and deploying applications.',
  },
  {
    get image_1() {
      return (
        <StaticImage
          src={qa1}
          alt={`${this.title} 1 | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    get image_2() {
      return (
        <StaticImage
          src={qa2}
          alt={`${this.title} 2 | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    get image_3() {
      return (
        <StaticImage
          src={qa3}
          alt={`${this.title} 3 | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    num: '+5',
    title: 'Quality assurance specialists',
    description:
      'They enhance the web development processes and detect errors in the digital product.',
  },
]

export default TeamProjectSuccessData
