import approachImage from '~/assets/images/approach/approach-tools-helping-us.svg?file'
import logoRedImage from '~/assets/images/approach/logo-red-codica.svg?file'
import Fade from '~/components/shared/Fade'
import { cx } from '~/utils'

import * as containerStyles from './ToolsHelpingUs.module.scss'

const ToolsHelpingUs = () => (
  <div className="container container-md">
    <div className="row">
      <div
        className={cx(containerStyles.toolsHelpingUsSection__wrapper, 'col-12')}
      >
        <div className={containerStyles.toolsHelpingUsSection__image}>
          <img
            src={approachImage}
            alt="Tools helping us here | Codica"
            title="Tools helping us here"
            width="100%"
            height="100%"
            loading="lazy"
          />
          <div className={containerStyles.toolsHelpingUsSection__logo}>
            <img
              src={logoRedImage}
              alt="Logo | Codica"
              title="Logo"
              width="64"
              height="74"
              loading="lazy"
            />
          </div>
        </div>
        <div
          className={cx(
            containerStyles.toolsHelpingUsSection__techStackWrapper,
            containerStyles.toolsHelpingUsSection__techStackWrapper_trello,
          )}
        >
          <Fade triggerOnce direction="up">
            <div
              className={cx(
                containerStyles.toolsHelpingUsSection__techStack,
                containerStyles.toolsHelpingUsSection__techStack_trello,
              )}
            />
            <div
              className={containerStyles.toolsHelpingUsSection__techStackTitle}
            >
              Trello
            </div>
          </Fade>
        </div>
        <div
          className={cx(
            containerStyles.toolsHelpingUsSection__techStackWrapper,
            containerStyles.toolsHelpingUsSection__techStackWrapper_jira,
          )}
        >
          <Fade triggerOnce direction="up">
            <div
              className={cx(
                containerStyles.toolsHelpingUsSection__techStack,
                containerStyles.toolsHelpingUsSection__techStack_jira,
              )}
            />
            <div
              className={containerStyles.toolsHelpingUsSection__techStackTitle}
            >
              Jira
            </div>
          </Fade>
        </div>
        <div
          className={cx(
            containerStyles.toolsHelpingUsSection__techStackWrapper,
            containerStyles.toolsHelpingUsSection__techStackWrapper_gitlab,
          )}
        >
          <Fade triggerOnce direction="up">
            <div
              className={cx(
                containerStyles.toolsHelpingUsSection__techStack,
                containerStyles.toolsHelpingUsSection__techStack_gitlab,
              )}
            />
            <div
              className={containerStyles.toolsHelpingUsSection__techStackTitle}
            >
              GitLab
            </div>
          </Fade>
        </div>
        <div
          className={cx(
            containerStyles.toolsHelpingUsSection__techStackWrapper,
            containerStyles.toolsHelpingUsSection__techStackWrapper_github,
          )}
        >
          <Fade triggerOnce direction="up">
            <div
              className={cx(
                containerStyles.toolsHelpingUsSection__techStack,
                containerStyles.toolsHelpingUsSection__techStack_github,
              )}
            />
            <div
              className={containerStyles.toolsHelpingUsSection__techStackTitle}
            >
              GitHub
            </div>
          </Fade>
        </div>
        <div
          className={cx(
            containerStyles.toolsHelpingUsSection__techStackWrapper,
            containerStyles.toolsHelpingUsSection__techStackWrapper_slack,
          )}
        >
          <Fade triggerOnce direction="up">
            <div
              className={cx(
                containerStyles.toolsHelpingUsSection__techStack,
                containerStyles.toolsHelpingUsSection__techStack_slack,
              )}
            />
            <div
              className={containerStyles.toolsHelpingUsSection__techStackTitle}
            >
              Slack
            </div>
          </Fade>
        </div>
        <div
          className={cx(
            containerStyles.toolsHelpingUsSection__techStackWrapper,
            containerStyles.toolsHelpingUsSection__techStackWrapper_axure,
          )}
        >
          <Fade triggerOnce direction="up">
            <div
              className={cx(
                containerStyles.toolsHelpingUsSection__techStack,
                containerStyles.toolsHelpingUsSection__techStack_axure,
              )}
            />
            <div
              className={containerStyles.toolsHelpingUsSection__techStackTitle}
            >
              Axure
            </div>
          </Fade>
        </div>
        <div
          className={cx(
            containerStyles.toolsHelpingUsSection__techStackWrapper,
            containerStyles.toolsHelpingUsSection__techStackWrapper_figma,
          )}
        >
          <Fade triggerOnce direction="up">
            <div
              className={cx(
                containerStyles.toolsHelpingUsSection__techStack,
                containerStyles.toolsHelpingUsSection__techStack_figma,
              )}
            />
            <div
              className={containerStyles.toolsHelpingUsSection__techStackTitle}
            >
              Figma
            </div>
          </Fade>
        </div>
      </div>
    </div>
  </div>
)

export default ToolsHelpingUs
