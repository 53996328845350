import React from 'react'

import Fade from '~/components/shared/Fade'
import { DataContentProps } from '~/types/helper'

import * as containerStyles from './TeamProjectSuccess.module.scss'

export interface TeamProjectSuccessItem {
  image_1: React.JSX.Element
  image_2: React.JSX.Element
  image_3?: React.JSX.Element
  title: string
  description: string
  num?: string
}

const TeamProjectSuccess = ({
  dataContent,
}: DataContentProps<TeamProjectSuccessItem>) => (
  <div className="container container-md">
    <div className="row">
      {dataContent.map(
        ({ title, description, num, image_1, image_2, image_3 }) => (
          <div className="col-12 col-lg-4 col-md-6" key={title}>
            <Fade triggerOnce direction="up">
              <div className={containerStyles.teamProjectSuccess__card}>
                <div className={containerStyles.teamProjectSuccess__images}>
                  {image_1}
                  {image_2}
                  {image_3}
                  {num && (
                    <div
                      className={containerStyles.teamProjectSuccess__draftBlock}
                    >
                      {num}
                    </div>
                  )}
                </div>
                <div className={containerStyles.teamProjectSuccess__title}>
                  {title}
                </div>
                <div
                  className={containerStyles.teamProjectSuccess__description}
                >
                  {description}
                </div>
              </div>
            </Fade>
          </div>
        ),
      )}
    </div>
  </div>
)

export default TeamProjectSuccess
