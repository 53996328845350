import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

import SEO from '~/components/shared/Seo'
import { AllArticlePageProps } from '~/types/common-props'
import { fileToImageLikeData, getPageMeta } from '~/utils'
import Approach from '~/views/Approach/Approach.view'

const ApproachPage = () => <Approach />

export default ApproachPage

export const query = graphql`
  query {
    strapiPage(page_name: { eq: "Approach" }) {
      ...MetaTagInformationPages
    }
  }
`

export const Head = ({ data }: AllArticlePageProps) => {
  const { meta_title, meta_description, og_image } = getPageMeta(
    data.strapiPage,
  )

  return (
    <SEO
      title={meta_title}
      description={meta_description}
      urlImage={getSrc(fileToImageLikeData(og_image?.localFile)) as string}
      url="https://www.codica.com/approach/"
    />
  )
}
