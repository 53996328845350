import { StaticImage, getImage } from 'gatsby-plugin-image'

import processLine from '~/assets/images/approach/approach-frame.svg?file'
import DevelopmentProcess from '~/components/blocks/DevelopmentProcess'
import HeadSection from '~/components/blocks/HeadSection'
import Testimonial from '~/components/blocks/TestimonialPost'
import CallToAction from '~/components/call-to-actions/CallToAction'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import DiscoverWorksItem from '~/components/items/DiscoverWorksItem'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import SuccessStoriesSliderSection from '~/components/sections/SuccessStoriesSliderSection'
import AgileMindsetApproach from '~/mock/approach/AgileMindsetApproach'
import ApproachProcess from '~/mock/approach/ApproachProcess'
import TeamProjectSuccessData from '~/mock/approach/TeamProjectSuccessData'
import { cx, fileToImageLikeData } from '~/utils'
import InfoRoundBlock from '~/views/Approach/components/InfoRoundBlock'
import PrimeSectionApproach from '~/views/Approach/components/PrimeSectionApproach'
import TeamProjectSuccess from '~/views/Approach/components/TeamProjectSuccess'
import ToolsHelpingUs from '~/views/Approach/components/ToolsHelpingUs'
import useApproachStaticQuery from '~/views/Approach/useApproachStaticQuery'
import CompanyPostSliderSection from '~/views/Company/components/CompanyPostSliderSection'
import ServicesTechStackSection from '~/views/Services/components/ServicesTechStackSection'

import * as containerStyles from './Approach.module.scss'

const clientName = 'Ben Alexander'

const Approach = () => {
  const query = useApproachStaticQuery()

  const portfolioIX = getImage(fileToImageLikeData(query.portfolioIX))
  const quoteCodicaCEOPhoto = getImage(
    fileToImageLikeData(query.quoteCodicaCEOPhoto),
  )
  const quoteCodicaMaxPhoto = getImage(
    fileToImageLikeData(query.quoteCodicaMaxPhoto),
  )

  return (
    <MainLayout scrollTop>
      <PrimeSectionApproach
        title="Our Approach"
        description={[
          {
            item: 'Our effective development approach is built on experience creating dozens of projects across various domains. It’s our priority to establish a process that keeps you informed on every stage of software development when we create something awesome for you.',
          },
          {
            item: 'We are eager to become a full-fledged partner for you, develop your businesses, help your web product scale, and boost revenue.',
          },
        ]}
      />

      <section className={containerStyles.agileApproachSection}>
        <HeadSection
          position="center"
          title="Our agile mindset"
          description="At Codica, we apply the flexible agile methodology to develop highly-effective and profitable projects."
          descriptionLarge
        />
        <ServicesIconSmallCard isBorder dataContent={AgileMindsetApproach} />
        <div className={containerStyles.agileApproachSection__testimonial}>
          <Testimonial
            image={quoteCodicaCEOPhoto}
            name="Dmytro"
            position="CEO & Founder at Codica"
            quote={[
              {
                text: '“I’m proud to say that Codica is a team with product-oriented thinking. Having an expert development team on board, we deliver great code quality, but this is not our only goal.',
              },
              {
                text: 'From the start of our collaboration, we focus on the value we deliver to each project. Applying our domain and technical expertise, we advise on the most efficient ways to make your product successful and profitable.”',
              },
            ]}
          />
        </div>
      </section>

      <section className={containerStyles.bestPracticesApproachSection}>
        <HeadSection
          position="center"
          title="Software development best practices"
          description="At Codica, we use leading technologies and practices to create successful web projects."
          titleLarge
        />
        <InfoRoundBlock />
      </section>

      <section className={containerStyles.projectSuccessApproachSection}>
        <HeadSection
          position="center"
          title="Reliable team for project success"
          description="A skilled software development team is the most vital part of the success of any business. And we put together the best professionals!"
          titleLarge
        />
        <TeamProjectSuccess dataContent={TeamProjectSuccessData} />
      </section>

      <div className={containerStyles.testimonialApproachSection}>
        <Testimonial
          image={quoteCodicaMaxPhoto}
          name="Maksym"
          position="Tech Lead & Rails expert at Codica"
          quote={[
            {
              text: '“I am proud to say that our team truly cares about each project we are working on. It is our priority to make sure that custom products and apps we create bring the best results to our clients.',
            },
            {
              text: 'To achieve this, when providing customized software development services, we begin with careful analysis of business requirements. We further align the requirements with possible solutions, and advise on the most efficient way to proceed.”',
            },
          ]}
        />
      </div>

      <section className={containerStyles.toolslApproachSection}>
        <HeadSection
          position="center"
          title="Tools helping us here"
          description="These are the top tools that help our specialists to keep productive and work efficiently."
        />
        <ToolsHelpingUs />
      </section>

      <section
        className={cx(
          containerStyles.processApproachSection,
          'processApproachSection',
        )}
      >
        <HeadSection
          position="center"
          title="Our proven web development process"
          description="At Codica, we have all skills and tools to execute an optimized and seamless software development process."
          titleLarge
          descriptionLarge
        />
        <DevelopmentProcess
          dataImage={processLine}
          dataContent={ApproachProcess}
        />
      </section>

      <section className={containerStyles.techStackApproachSection}>
        <HeadSection
          position="center"
          title="Best tech stack to power software development"
          description="Picking the right technologies is a crucial factor in the project’s success. At Codica, we adopt the most efficient and modern technology stacks to make your web solution very competitive and fulfill all your business requirements."
          titleFullWidth
          descriptionLarge
        />
        <ServicesTechStackSection />
      </section>

      <CallToAction
        title="Let’s build a great online business for you!"
        subtitle="Tell us about your project!"
        classNameForGA="servicesCTA_1"
        linkName="Get in touch"
        link="/contacts/"
      />

      <section className={containerStyles.caseApproachSection}>
        <HeadSection
          position="center"
          title="Excellent results with our approach"
        />
        <DiscoverWorksItem
          key={3}
          gradient="gradientIx"
          image={portfolioIX}
          header="Custom software development for travel marketplace"
          title="iExpedition is an Australia-based expedition and adventure travel company that offers polar cruises. The travel marketplace platform allows tourists to search and book Arctic and Antarctic trips."
          resultsLeftTitle="$2 mln"
          resultsLeft="worth of sales brought by the website within the first year."
          resultsRightTitle="358%"
          resultsRight="traffic growth for the past 2 years thanks to search engine optimization."
          link="travel-management-platform"
          review="“I highly recommend Codica team as a proven software development company. And I can honestly say that if it wasn’t for Codica, I do not know if I could have managed to bring our product alive for the budget that we had.”"
          clientPhoto={
            <StaticImage
              src="../../assets/images/avatars/clients/client-iExpedition.png"
              alt={`${clientName} | Codica`}
              title={clientName}
              width={50}
              height={50}
            />
          }
          clientName={clientName}
          clientPosition="Director, iExpedition"
        />
      </section>

      <section className={containerStyles.successStoriesApproachSection}>
        <HeadSection position="center" title="Success stories" />
        <SuccessStoriesSliderSection btnName="View all our works" />
      </section>

      <section className={containerStyles.recentArticlesApproachSection}>
        <CompanyPostSliderSection />
      </section>

      <PrimeContactFormSection title="Let’s turn your idea into a successful product!" />
    </MainLayout>
  )
}

export default Approach
