// extracted by mini-css-extract-plugin
export var agileApproachSection = "m_5";
export var agileApproachSection__testimonial = "m_6";
export var bestPracticesApproachSection = "m_7";
export var caseApproachSection = "m_bg";
export var processApproachSection = "m_bc";
export var projectSuccessApproachSection = "m_8";
export var recentArticlesApproachSection = "m_bf";
export var successStoriesApproachSection = "m_bh";
export var techStackApproachSection = "m_bd";
export var testimonialApproachSection = "m_9";
export var toolslApproachSection = "m_bb";