import { ExpertiseSectionMock } from '~/types/mock'

const AgileMindsetApproach: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_16',
    title: 'Weekly designed sprints',
    description:
      'We divide the process into sprints to see specific results at the early stage of development.',
  },
  {
    icon: 'spriteIcon_69',
    title: 'Daily standups',
    description:
      'Daily meetings help share gained results and coordinate the next efforts in sprint delivery.',
  },
  {
    icon: 'spriteIcon_37',
    title: 'Strong communications',
    description:
      'We believe that broad engagement with clients is the fundamental key to project success.',
  },
  {
    icon: 'spriteIcon_27',
    title: 'Regular retrospectives',
    description:
      'At the end of each sprint, we overview results and identify actions for following improvements.',
  },
  {
    icon: 'spriteIcon_58',
    title: 'In-house project manager',
    description:
      'Our PM bridges the gap between the dev team and a client, helping to get the expected results.',
  },
  {
    icon: 'spriteIcon_67',
    title: 'Fully transparent process',
    description:
      'Transparency helps us to foster communication and collaboration during development.',
  },
]

export default AgileMindsetApproach
