// extracted by mini-css-extract-plugin
export var approachInfoRoundBlock__between = "c9_x6";
export var approachInfoRoundBlock__card = "c9_x5";
export var approachInfoRoundBlock__contentFive = "c9_yh";
export var approachInfoRoundBlock__contentFour = "c9_yg";
export var approachInfoRoundBlock__contentOne = "c9_yc";
export var approachInfoRoundBlock__contentSix = "c9_yj";
export var approachInfoRoundBlock__contentThree = "c9_yf";
export var approachInfoRoundBlock__contentTwo = "c9_yd";
export var approachInfoRoundBlock__description = "c9_yb";
export var approachInfoRoundBlock__icon = "c9_x8";
export var approachInfoRoundBlock__imageDesktop = "c9_x7";
export var approachInfoRoundBlock__title = "c9_x9";